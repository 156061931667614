import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { UtilService } from './util.service';

@Injectable()
export class SessionkeyService {
  public userid: string = '';
  public isLoggedIn: boolean = false;
  public isLoggedInAdmin: boolean = false;
  public token: string;
  public expires: string;
  public loginresult: Subject<any> = new Subject();
  public loginedresult: Subject<any> = new Subject();
  public role: string = '';
  public usertoken: string = '';
  public admin_userid: string = '';
  public admin_token: string;
  public admin_expires: string;
  public admin_usertoken: string = '';

  constructor(
    public http: HttpClient,
    private storage: Storage,
    public Util: UtilService
  ) {
    //this.getkey();
  }

  setkey(usertoken: string) {
    this.storage.set('usertoken', usertoken);
    console.log(usertoken + 'を保存しました。');
  }

  login(userid: string, password: string) {
    //idパスワードをみて、OKならalive tokenを期限とともに保存
    //いずれはサーバにつなぎにいって、期限をもらうようにする
    console.log('ログイン認証をします。');
    this.http
      .post(
        environment.apiurl + 'users/api_login.json?' + new Date().getTime(),
        {
          userid: userid,
          password: password,
        }
        //loginではトークンを付与しない
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          this.userid = userid;
          localStorage.setItem('userid', userid);
          localStorage.setItem('usertoken', data.usertoken);
          localStorage.setItem('role', data.role);
          localStorage.setItem('username', data.username);
          this.role = data.role;
          this.Util.setusertoken(data.usertoken);
          console.log('トークン:' + data.usertoken + 'を取得しました。');

          //const expires = moment().add(86400, 'second');
          const expires = data.expires;
          //debug().log(expires.valueOf());
          //debug().log(typeof expires.valueOf());
          localStorage.setItem('expires', JSON.stringify(expires.valueOf()));
          this.loginresult.next(data.usertoken);
          //return true;
        },
        (error) => {
          // console.log(error)
          if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
          } else {
            console.error(
              `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
          }
          this.remove();
          this.loginresult.next(false);
        },
        () => {
          console.log('処理が完了しました。');
        }
      );
    return this.loginresult;
  }

  adminlogin(userid: string, password: string) {
    console.log('adminログイン認証をします。');
    this.http
      .post(
        environment.apiurl + 'users/api_login.json?' + new Date().getTime(),
        {
          userid: userid,
          password: password,
        }
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          this.userid = userid;
          localStorage.setItem('admin_userid', userid);
          localStorage.setItem('admin_usertoken', data.usertoken);
          localStorage.setItem('role', data.role);
          localStorage.setItem('admin_username', data.username);
          this.role = data.role;
          this.Util.setusertoken(data.usertoken);
          console.log('トークン:' + data.usertoken + 'を取得しました。');

          //const expires = moment().add(86400, 'second');
          const expires = data.expires;
          //debug().log(expires.valueOf());
          //debug().log(typeof expires.valueOf());
          localStorage.setItem(
            'admin_expires',
            JSON.stringify(expires.valueOf())
          );
          this.loginresult.next(data.usertoken);
          //return true;
        },
        (error) => {
          // console.log(error)
          if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
          } else {
            console.error(
              `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
          }
          this.remove();
          this.loginresult.next(false);
        },
        () => {
          console.log('処理が完了しました。');
        }
      );
    return this.loginresult;
  }

  isLogined(admin: boolean = false) {
    //console.log('セッションチェックします。');
    //先にlocalのexpiresをチェック。
    //これが切れていればそもそもログインしなおしが必要
    var expire = localStorage.getItem('expires');
    if (expire === null) {
      console.log('expireがありません。');
      this.isLoggedIn = false;
      return false;
    }
    //console.log(localStorage.getItem('token'));
    //console.log(parseInt(expire));
    //console.log(moment().unix());
    if (parseInt(expire) < moment().unix()) {
      console.log('時間切れです。');
      this.isLoggedIn = false;
      return false;
    }
    //時間切れになっていなければ、とりあえずは、進める。
    //ただし、APIトークンとして投げたときどうかはここでは見ない。
    this.userid = localStorage.getItem('userid');
    //this.token = localStorage.getItem('token');
    this.usertoken = localStorage.getItem('usertoken');
    this.expires = localStorage.getItem('expires');
    this.isLoggedIn = true;
    console.log('セッションは有効です。', 'usertoken: ', this.usertoken);
    return true;
  }

  isLoginedAdmin() {
    //先にlocalのexpiresをチェック。
    //これが切れていればそもそもログインしなおしが必要
    var expire = localStorage.getItem('admin_expires');
    if (expire === null) {
      console.log('admin_expireがありません。');
      this.isLoggedInAdmin = false;
      return false;
    }
    //console.log(localStorage.getItem('token'));
    //console.log(parseInt(expire));
    //console.log(moment().unix());
    if (parseInt(expire) < moment().unix()) {
      console.log('時間切れです。');
      this.isLoggedInAdmin = false;
      return false;
    }
    //時間切れになっていなければ、とりあえずは、進める。
    //ただし、APIトークンとして投げたときどうかはここでは見ない。
    this.admin_userid = localStorage.getItem('admin_userid');
    //this.token = localStorage.getItem('token');
    this.admin_usertoken = localStorage.getItem('admin_usertoken');
    this.admin_expires = localStorage.getItem('admin_expires');
    this.isLoggedInAdmin = true;
    console.log(
      'セッションは有効です。',
      'admin_usertoken: ',
      this.admin_usertoken
    );
    return true;
  }

  async logout() {
    //端末上のAlive token削除。
    this.isLoggedIn = false;
    this.remove();
  }

  async remove() {
    this.userid = '';
    this.isLoggedIn = false;
    //localStorage.removeItem('userid');
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
    localStorage.removeItem('usertoken');
  }

  async removeuserid() {
    this.userid = '';
    this.isLoggedIn = false;
    localStorage.removeItem('userid');
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
    localStorage.removeItem('usertoken');
  }

  async adminlogout() {
    //端末上のAlive token削除。
    this.adminremove();
  }

  async adminremove() {
    this.userid = '';
    this.isLoggedInAdmin = false;
    localStorage.removeItem('admin_userid');
    localStorage.removeItem('admin_token');
    localStorage.removeItem('admin_expires');
    localStorage.removeItem('admin_usertoken');
  }

  getToken() {
    return localStorage.getItem('usertoken');
  }

  is_admin() {
    return localStorage.getItem('role') === 'admin';
  }

  is_root() {
    return localStorage.getItem('role') === 'root';
  }

  getusername(admin: boolean = false) {
    var key = 'username';
    if (admin) {
      key = 'admin_username';
    }
    return localStorage.getItem(key);
  }

  getusernameadmin() {
    return this.getusername(true);
  }
}
