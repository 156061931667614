import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class PartService {
  public currentId: string = null;
  public lists = [];
  public data: any = {
    Part: {
      name: '',
      id: null,
      partscategory_id: null,
      category_id: null,
      comment: null,
      number: null,
      roofphoto_id: null,
    },
  };
  public partscategory;
  public category;
  public elementcategory;
  public nextnum: string;
  public fromroof: string = null;

  constructor(public http: HttpClient, public Util: UtilService) {}

  create() {
    //this.data = { name: '', id: null };
  }

  find() {
    //取ってきてlistsを更新。更新されたタイミングで表示される
    return new Promise((resolve, fail) => {
      this.http
        .get(
          environment.apiurl + 'parts/api_index.json?' + new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            this.lists = data.data;
            resolve(data.data);
          },
          (error) => {
            fail();
          }
        );
    });
  }

  read(id: string = null) {
    if (id === null) {
      id = this.currentId;
    }
    if (id !== null) {
      this.get(id);
    } else {
      console.error('idがnullです。');
    }
  }

  async get(id: string) {
    this.reset();
    return new Promise((resolve, fail) => {
      this.http
        .get(
          environment.apiurl +
            'parts/api_view/' +
            id +
            '.json?' +
            new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            //console.log(data);
            //console.log(data.data.Partscategory);
            this.data = data.data;
            console.log(this.data);
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  save() {
    console.log(this.data.Part);
    return new Promise((resolve, fail) => {
      this.http
        .post(
          environment.apiurl +
            'parts/api_edit/' +
            this.data.Part.id +
            '.json?' +
            new Date().getTime(),
          this.data.Part,
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  delete(id: string) {
    if (confirm('削除しますか？')) {
      return new Promise((resolve, fail) => {
        this.http
          .post(
            environment.apiurl +
              'parts/api_delete/' +
              id +
              '.json?' +
              new Date().getTime(),
            {},
            { headers: this.Util.httpheader }
          )
          .subscribe(
            (data: any) => {
              //console.log(data);
              this.find();
              resolve(true);
            },
            (error) => {
              fail(false);
            }
          );
      });
    }
  }

  async unlink(id: string) {
    return new Promise((resolve, fail) => {
      this.http
        .post(
          environment.apiurl + 'parts/api_unlink/' + id + '.json',
          {},
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  savesort() {
    console.log(this.lists);
    var lists = [];
    for (let i = 0; i < this.lists.length; i++) {
      lists.push(this.lists[i].Partscategory.id);
    }
    console.log(lists);
    //idの並びだけ投げれば、それらに順にsortを保存すればよい。
    return new Promise((resolve, fail) => {
      this.http
        .post(
          environment.apiurl +
            'parts/api_sortsave.json?' +
            new Date().getTime(),
          { sortvalue: JSON.stringify(lists) },
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  async getCategoryLists() {
    return new Promise((resolve, fail) => {
      this.http
        .get(
          environment.apiurl +
            'items/api_categories.json?' +
            new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            this.partscategory = data.data.partscategory;
            this.category = data.data.category;
            this.elementcategory = data.data.elementcategory;
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  getPartId(item_id: string, photo_id: string = null) {
    return new Promise((resolve, fail) => {
      this.http
        .get(
          environment.apiurl +
            'parts/api_getpartid.json?item_id=' +
            item_id +
            '&' +
            new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            //console.log(data);
            this.currentId = data.data.part_id;
            this.nextnum = this.data.Part.number = data.data.nextnum;
            this.data.Part.photo_id = photo_id;
            resolve(data.data.part_id);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  reset() {
    console.log('リセット');
    this.data = {
      Part: {
        name: '',
        id: null,
        partscategory_id: null,
        category_id: null,
        comment: null,
        number: null,
      },
    };
  }

  selectDefaultPhoto(id, photo_id) {
    return new Promise((resolve, fail) => {
      this.Util.http_post(
        environment.apiurl + 'parts/api_defaultphoto/' + id + '.json',
        { photo_id: photo_id }
      ).then((result: any) => {
        resolve(true);
      });
    });
  }
}
