import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UtilService } from './util.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class VersioncheckService {
  public version: string = '';
  public toast: any;
  constructor(
    public Util: UtilService,
    public toastController: ToastController
  ) {}

  versioncheck() {
    //console.log(this.version);
    //自分のバージョン番号
    return new Promise((resolve, fail) => {
      this.Util.http_post(
        environment.apiurl + 'items/api_appversioncheck/.json',
        { version: this.version }
      ).then(async (result: any) => {
        if (result.data.version != null) {
          console.log(result);
          if (this.Util.platform.is('cordova')) {
            this.toast = await this.toastController.create({
              message:
                'アップデートがあります。ダウンロードページに移動しますか？',
              duration: 8000,
              position: 'bottom',
              buttons: [
                {
                  text: 'はい',
                  handler: () => {
                    var url = environment.apiurl + 'files/app/';
                    console.log(url);
                    window.open(url, 'app-download');
                  },
                },
                {
                  text: 'あとで',
                  role: 'cancel',
                  handler: () => {
                    console.log('cancel clicked');
                  },
                },
              ],
            });
            this.toast.present();
          } else {
            this.toast = await this.toastController.create({
              message:
                'アップデートがあります。左メニューの「アップデート」より更新してください。',
              duration: 8000,
              position: 'bottom',
            });
            this.toast.present();
          }
        }
        resolve(result.data);
      });
    });
  }
}
