import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { AdminauthGuard } from './auth/adminauth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomePageModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomePageModule),
      },
      {
        path: 'partscategories',
        children: [
          {
            path: 'index',
            loadChildren: () =>
              import('./partscategories/index/index.module').then(
                (m) => m.IndexPageModule
              ),
          },
          {
            path: 'edit/:id',
            loadChildren: () =>
              import('./partscategories/edit/edit.module').then(
                (m) => m.EditPageModule
              ),
          },
        ],
      },
      {
        path: 'elementcategories',
        children: [
          {
            path: 'index',
            loadChildren: () =>
              import('./elementcategories/index/index.module').then(
                (m) => m.IndexPageModule
              ),
          },
          {
            path: 'edit/:id',
            loadChildren: () =>
              import('./elementcategories/edit/edit.module').then(
                (m) => m.EditPageModule
              ),
          },
        ],
      },
      {
        path: 'versioninfo',
        loadChildren: () =>
          import('./versioninfo/versioninfo.module').then(
            (m) => m.VersioninfoPageModule
          ),
      },
      {
        path: 'codepushcheck',
        loadChildren: () =>
          import('./codepushcheck/codepushcheck.module').then(
            (m) => m.CodepushcheckPageModule
          ),
      },
      {
        path: 'categories',
        children: [
          {
            path: 'edit/:id',
            loadChildren: () =>
              import('./categories/edit/edit.module').then(
                (m) => m.EditPageModule
              ),
          },
          {
            path: 'index',
            loadChildren: () =>
              import('./categories/index/index.module').then(
                (m) => m.IndexPageModule
              ),
          },
        ],
      },
      {
        path: 'roofcategories',
        children: [
          {
            path: 'edit/:id',
            loadChildren: () =>
              import('./roofcategories/edit/edit.module').then(
                (m) => m.EditPageModule
              ),
          },
          {
            path: 'index',
            loadChildren: () =>
              import('./roofcategories/index/index.module').then(
                (m) => m.IndexPageModule
              ),
          },
        ],
      },
      {
        path: 'items',
        children: [
          {
            path: 'index',
            loadChildren: () =>
              import('./items/index/index.module').then(
                (m) => m.IndexPageModule
              ),
          },
          {
            path: 'view/:id',
            loadChildren: () =>
              import('./items/view/view.module').then((m) => m.ViewPageModule),
          },
          {
            path: 'edit/:id',
            loadChildren: () =>
              import('./items/edit/edit.module').then((m) => m.EditPageModule),
          },
          {
            path: 'createpdf/:id',
            loadChildren: () =>
              import('./items/createpdf/createpdf.module').then(
                (m) => m.CreatepdfPageModule
              ),
          },
          {
            path: 'photoview/:id/:mode',
            loadChildren: () =>
              import('./items/photoview/photoview.module').then(
                (m) => m.PhotoviewPageModule
              ),
          },
        ],
      },
      {
        path: 'parts',
        children: [
          {
            path: 'edit/:id',
            loadChildren: () =>
              import('./parts/edit/edit.module').then((m) => m.EditPageModule),
          },
          {
            path: 'view/:id',
            loadChildren: () =>
              import('./parts/view/view.module').then((m) => m.ViewPageModule),
          },
          {
            path: 'roofphotoselect/:id',
            loadChildren: () =>
              import('./parts/roofphotoselect/roofphotoselect.module').then(
                (m) => m.RoofphotoselectPageModule
              ),
          },
        ],
      },
      {
        path: 'photos',
        children: [
          {
            path: 'select',
            loadChildren: () =>
              import('./photos/select/select.module').then(
                (m) => m.SelectPageModule
              ),
          },
          {
            path: 'edit/:id',
            loadChildren: () =>
              import('./photos/edit/edit.module').then((m) => m.EditPageModule),
          },
          {
            path: 'history',
            loadChildren: () =>
              import('./photos/history/history.module').then(
                (m) => m.HistoryPageModule
              ),
          },
        ],
      },
      {
        path: 'sqlite/list',
        loadChildren: () =>
          import('./sqlite/list/list.module').then((m) => m.ListPageModule),
      },

      {
        path: 'logout',
        loadChildren: () =>
          import('./auth/logout/logout.module').then((m) => m.LogoutPageModule),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '',
    children: [
      {
        path: 'admin',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./admindashboard/admindashboard.module').then(
                (m) => m.AdmindashboardPageModule
              ),
          },
          {
            path: 'dashboard2',
            loadChildren: () =>
              import('./admindashboard/admindashboard.module').then(
                (m) => m.AdmindashboardPageModule
              ),
          },
          {
            path: 'items',
            children: [
              {
                path: 'index',
                loadChildren: () =>
                  import('./items/adminindex/adminindex.module').then(
                    (m) => m.AdminindexPageModule
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'adminlogout',
        loadChildren: () =>
          import('./auth/adminlogout/adminlogout.module').then(
            (m) => m.AdminlogoutPageModule
          ),
      },
    ],
    canActivate: [AdminauthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'adminlogin',
    loadChildren: () =>
      import('./auth/adminlogin/adminlogin.module').then(
        (m) => m.AdminloginPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
