import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-downloading',
  templateUrl: './downloading.page.html',
  styleUrls: ['./downloading.page.scss'],
})
export class DownloadingPage implements OnInit {
  constructor(
    public modalController: ModalController,
    public Util: UtilService
  ) {}

  ngOnInit() {}

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
