import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ItemService } from 'src/app/services/item.service';
import { PartService } from 'src/app/services/part.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-partpictureselect',
  templateUrl: './partpictureselect.page.html',
  styleUrls: ['./partpictureselect.page.scss'],
})
export class PartpictureselectPage implements OnInit {
  @Input() partdata: any;
  public selected;

  constructor(
    public modalController: ModalController,
    public Part: PartService,
    public Util: UtilService,
    public Item: ItemService
  ) {}

  ngOnInit() {
    //console.log(this.partdata.Photo);
  }

  ionViewWillEnter() {
    console.log(this.partdata);
    this.selected = this.partdata.Photo[0].id;
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  select(id) {
    this.selected = id;
  }

  submit() {
    console.log(this.selected);
    this.Part.selectDefaultPhoto(this.partdata.id, this.selected).then(
      (result: any) => {
        this.Item.read();
      }
    );
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
