import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  NavigationExtras,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionkeyService } from '../services/sessionkey.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AdminauthGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionkey: SessionkeyService,
    public platform: Platform
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log('ログイン確認');
    var result = this.sessionkey.isLoginedAdmin();
    if (!result) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          error: 'ログインしてください。',
        },
      };
      this.router.navigateByUrl('/adminlogin', navigationExtras);
      return false;
    }
    return true;
  }
}
