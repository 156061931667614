import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';

import { AppRoutingModule } from './app-routing.module';
//import { CodePush } from '@ionic-native/code-push/ngx';
//import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
//import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage';
import { File } from '@ionic-native/file/ngx';
import {
  FileTransfer,
  //FileUploadOptions,
  //FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { SessionkeyService } from './services/sessionkey.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PwaUpdateService } from './services/pwa-update.service';
import { PartpictureselectPage } from './modal/partpictureselect/partpictureselect.page';
import { DownloadingPage } from './modal/downloading/downloading.page';
import { FileOpener } from '@ionic-native/file-opener/ngx';

@NgModule({
  declarations: [AppComponent, PartpictureselectPage, DownloadingPage],
  entryComponents: [PartpictureselectPage, DownloadingPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      swipeBackEnabled: false, //入れたけど効かない
    }),
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      //enabled: environment.production,
      enabled: false,
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    //StatusBar,
    SplashScreen,
    //CodePush,
    //BarcodeScanner,
    Camera,
    File,
    //FileTransfer,
    //FileOpener,
    PhotoLibrary,
    SessionkeyService,
    //PwaUpdateService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
