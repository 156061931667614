import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  constructor() {}

  keywordsearch(keyword: string, target: string) {
    console.log(keyword, target);
    target = this.hiragana2katakana(this.tolowercase(this.zen2han(target)));
    keyword = this.hiragana2katakana(this.tolowercase(this.zen2han(keyword)));
    if (target.indexOf(keyword.trim()) !== -1) {
      return true;
    }
    if (target.replace(/-/g, '').indexOf(keyword.trim()) !== -1) {
      return true;
    }
    if (target.replace(/-/g, '').indexOf(keyword.trim()) !== -1) {
      return true;
    }
    return false;
  }

  zen2han(str: string) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s: string) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  }

  tolowercase(str: string) {
    return str.toLowerCase();
  }

  hiragana2katakana(str: string) {
    return str.replace(/[\u3041-\u3096]/g, function (match) {
      var chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  }
}
