import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UtilService } from './util.service';
import { FunctionsService } from './functions.service';
import * as _moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  public lists: any = null;
  public currentId = '';
  public currentPartId: string = '';
  public currentRoofId: string = '';
  public currentPhotoId: string = '';
  public data: any = {
    Item: {
      id: null,
      name: '',
      address: '',
      reason: '',
    },
  };
  public result: any;
  public roofcategory;
  public lists_filtered: any = null;

  constructor(
    public http: HttpClient,
    public Util: UtilService,
    public Functions: FunctionsService
  ) {}

  setId(id: string) {
    this.currentId = id;
    this.read();
  }

  reset() {
    this.data = {
      Item: {
        id: null,
        name: '',
        address: '',
        reason: '',
      },
    };
  }

  read(id: string = null) {
    return new Promise((resolve, fail) => {
      //idでデータを引っ張ってきて、this.dataに入れる
      if (id === null) {
        id = this.currentId;
      }
      if (id === null) {
        return;
      }
      if (this.data.Item?.id !== id) {
        //データ初期化
        this.reset();
      }
      this.Util.http_get(
        environment.apiurl + 'items/api_view/' + id + '.json'
      ).then((result: any) => {
        this.data = result.data;
        this.data.Item.earthquakeinsurance =
          result.data.Item.earthquakeinsurance == true ? '1' : '0';
        //console.log(this.data);
        resolve(result.data);
      });
    });
  }

  find(filter: string = 'all') {
    this.lists = null;
    const url = environment.apiurl + 'items/api_index.json';
    this.Util.http_get(url).then((result: any) => {
      console.log(result);
      this.lists = result.data;
      this.filter();
    });
  }

  public pptxdata: string = '';
  getPPTX() {
    return new Promise((resolve, fail) => {
      this.Util.http_get_binary(
        environment.apiurl + 'items/api_pptx/' + this.currentId + '.json'
      ).then((result: any) => {
        this.Util.readBase64(result).then((res: string) => {
          var base64String = res;
          //console.log(base64String);
          this.pptxdata = base64String;
          resolve(base64String);
        });
      });
    });
  }

  getRoofId(type: string = 'default') {
    var url =
      environment.apiurl +
      'items/api_getroofid/' +
      type +
      '.json?' +
      new Date().getTime();
    return new Promise((resolve, fail) => {
      this.http.get(url, { headers: this.Util.httpheader }).subscribe(
        (data: any) => {
          this.currentRoofId = data.roof_id;
          resolve(data.roof_id);
        },
        (error) => {
          fail(false);
        }
      );
    });
  }

  save(id: string, d: any = null, refetch: boolean = true) {
    if (d == null) {
      d = this.data.Item;
    }
    return new Promise((resolve, fail) => {
      this.http
        .post(
          environment.apiurl +
            'items/api_edit/' +
            id +
            '.json?' +
            new Date().getTime(),
          d,
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            if (refetch === true) {
              this.read(data.id);
            }
            if (id === 'new') {
              this.find();
            }
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  delete(id: string) {
    this.Util.http_post(
      environment.apiurl + 'items/api_delete/' + id + '.json',
      {}
    ).then((result: any) => {
      return true;
    });
  }

  async getRoofcategoryLists() {
    return new Promise((resolve, fail) => {
      this.http
        .get(
          environment.apiurl +
            'items/api_roofcategories.json?' +
            new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            this.roofcategory = data.data.roofcategory;
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  public insurancecompany;
  async getInsurancecompanyLists() {
    return new Promise((resolve, fail) => {
      this.http
        .get(
          environment.apiurl +
            'items/api_insurancecompanylists.json?' +
            new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            this.insurancecompany = data.data.insurancecompany;
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  public reasonlists;
  async getReasonLists() {
    return new Promise((resolve, fail) => {
      this.http
        .get(
          environment.apiurl +
            'items/api_reasonlists.json?' +
            new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            console.log(data);
            this.reasonlists = data.data.reason;
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  public filter_keyword: string = '';
  public filter_today: boolean = false;

  filter() {
    this.lists_filtered = {};
    this.lists_filtered = this.lists.filter((row: any) => {
      var target =
        row.Item.name +
        ' ' +
        row.Item.ruby +
        ' ' +
        row.Item.address +
        ' ' +
        row.Item.phone;
      if (this.filter_today == true) {
        console.log(this.filter_today);
        console.log(row.Item.visitdate);
        if (row.Item.visitdate == _moment().format('YYYY-MM-DD')) {
          if (this.filter_keyword != '') {
            if (this.Functions.keywordsearch(this.filter_keyword, target)) {
              return true;
            }
          } else {
            return true;
          }
        }
      } else if (this.filter_keyword != '') {
        console.log(this.filter_keyword);
        if (
          this.filter_keyword != '' &&
          this.Functions.keywordsearch(this.filter_keyword, target)
        ) {
          return true;
        }
      } else {
        return true;
      }
      return false;
    });
  }

  _filter_today() {
    this.lists_filtered = {};
    this.lists_filtered = this.lists.filter((row: any) => {
      if (
        //指定されている場合
        row.Item.visitdate == _moment().format('YYYY-MM-DD')
      ) {
        return true;
      }
    });
  }

  filter_clear() {
    this.lists_filtered = this.lists;
  }

  async getpdfdata(id) {
    this.reset();
    this.currentId = id;
    return new Promise((resolve, fail) => {
      this.Util.http_get(
        environment.apiurl + 'items/api_pdfdata/' + id + '.json'
      ).then((result: any) => {
        this.data = result.data;
        console.log(this.data);
        resolve(result.data);
      });
    });
  }

  public pdfdata: string = '';
  getPDF() {
    return new Promise((resolve, fail) => {
      this.Util.http_get_binary(
        environment.apiurl + 'items/api_pdf/' + this.currentId + '.json'
      ).then((result: any) => {
        this.Util.readBase64(result).then((res: string) => {
          var base64String = res;
          //console.log(base64String);
          this.pdfdata = base64String;
          resolve(base64String);
        });
      });
    });
  }

  public googleearthphoto = null;
  async getgoogleearthphoto(id) {
    console.log('可能ならばキャッシュして');
    return new Promise((resolve, fail) => {
      this.Util.http_get(
        environment.apiurl + 'items/api_readgoogleearthphoto/' + id + '.json'
      ).then((result: any) => {
        //console.log(result);
        this.googleearthphoto = 'data:image/jpeg;base64,' + result.data;
        resolve(true);
      });
    });
  }

  public insurancephoto = null;
  async getinsurancephoto(id) {
    console.log('可能ならばキャッシュして');
    return new Promise((resolve, fail) => {
      this.Util.http_get(
        environment.apiurl + 'items/api_readinsurancephoto/' + id + '.json'
      ).then((result: any) => {
        //console.log(result);
        this.insurancephoto = 'data:image/jpeg;base64,' + result.data;
        resolve(true);
      });
    });
  }

  public insurancephoto2 = null;
  async getinsurancephoto2(id) {
    console.log('可能ならばキャッシュして');
    return new Promise((resolve, fail) => {
      this.Util.http_get(
        environment.apiurl + 'items/api_readinsurancephoto2/' + id + '.json'
      ).then((result: any) => {
        //console.log(result);
        this.insurancephoto2 = 'data:image/jpeg;base64,' + result.data;
        resolve(true);
      });
    });
  }

  getpdfurl() {
    return environment.apiurl + 'items/api_pdf/' + this.data.Item.id + '.json';
    return environment.apiurl + 'items/api_delete/' + this.data.id + '.json';
  }
}
