import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PhotoService } from './services/photo.service';
import { environment } from '../environments/environment';
import { SessionkeyService } from './services/sessionkey.service';
import { UtilService } from './services/util.service';
import { Router, NavigationEnd } from '@angular/router';
//import { SwUpdate } from '@angular/service-worker';
import { Title } from '@angular/platform-browser';
import { VersioncheckService } from './services/versioncheck.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public version = '1.1.1';
  public is_desktop: boolean = false;
  public is_tablet: boolean = false;
  public is_debug: boolean = false;
  public is_android: boolean = false;
  public is_cordova: boolean = false;
  public is_pwa: boolean = false;
  public agent: string = '';
  public platforms: string = '';
  public is_admin: boolean = false;

  constructor(
    public platform: Platform,
    private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    public Photo: PhotoService,
    public sessionkey: SessionkeyService,
    public Util: UtilService,
    public router: Router,
    //public updates: SwUpdate,
    public titleService: Title,
    public versioncheck: VersioncheckService
  ) {
    this.initializeApp();
    if (!environment.production) {
      this.titleService.setTitle('(開発)屋根破損レポート');
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    //if (environment.production) localStorage.removeItem('expires'); //本番ではアップデートや再起動後ログインし直し
    this.versioncheck.version = this.version;
  }

  ngOnInit() {
    if (!environment.production) this.is_debug = true;

    //console.log(this.appVersion.getAppName());
    this.platform.ready().then(() => {
      //this.checkCodePush();
      //this.init();
    });
    this.is_desktop = this.platform.is('desktop');
    this.is_tablet = this.platform.is('tablet');
    this.is_android = this.platform.is('android');
    this.is_pwa = this.platform.is('pwa') === true;
    this.is_cordova = this.platform.is('cordova') === true;
    this.is_desktop = this.platform.is('desktop') === true;
    this.agent = window.navigator.userAgent;
    this.platforms = this.platform.platforms().join(',');
    this.is_admin = this.platform.url().match(/\/admin\//);

    this.Photo.loadSaved(false); //溜まると落ちる
  }

  pwaupdate() {
    if (
      confirm(
        'システムをアップデートします。入力中のデータはクリアされますので、保存してから実行してください。'
      )
    ) {
      this.Util.presentToast('システムをアップデートしています', 5000);
      setTimeout(
        function (that) {
          that.Util.presentToast('読み込みます', 1000);
        },
        3000,
        this
      );
      setTimeout(function () {
        document.location.reload();
        //window.location.reload();
        //this.Util.redirect('/');
        //this.updates.activateUpdate().then(() => document.location.reload());
      }, 3000);
      //this.updates.activateUpdate().then(() => document.location.reload());
    }
  }

  tologout(admin: boolean = false) {
    if (confirm('ログアウトしますか？')) {
      if (admin) {
        this.Util.redirect('/adminlogout');
      } else {
        this.Util.redirect('/logout');
      }
    }
  }

  tologoutadmin() {
    this.tologout(true);
  }

  movetodownload() {
    window.open(environment.apiurl + 'files/app/');
  }

  backtohome() {
    this.Util.redirecttoHome();
  }
}
