import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { UtilService } from './util.service';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  public camerahistory: any[] = [];
  public thumbs: any = {};
  public currentImage: string;
  public edittedImage: string;
  public name: String;
  public canvas: any;
  public mode: string; //photo(外観写真),roof(屋根俯瞰図写真)
  public workmode: string = '';
  public part_id: string;
  public item_id: string;
  public roof_id: string;
  public currentId: string;
  public toast;
  public data;
  public is_android;
  public type: string = null;
  public md5: string = '';

  constructor(
    public platform: Platform,
    public http: HttpClient,
    private storage: Storage,
    private toastController: ToastController,
    public photoLibrary: PhotoLibrary,
    public Util: UtilService
  ) {
    this.is_android = this.platform.is('android');
  }

  async get(id: string) {
    return new Promise((resolve, fail) => {
      this.setmode('');
      this.http
        .get(
          environment.apiurl +
            'photos/api_read/' +
            id +
            '.json?' +
            new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            //console.log(data.data);
            //console.log(data.data.Partscategory);
            this.data = data.data;
            if (data.data.Item.id !== null) {
              this.setmode('itemphoto');
              this.item_id = data.data.Item.id;
            } else if (data.data.Part.id !== null) {
              this.setmode('partphoto');
              this.part_id = data.data.Part.id;
              this.item_id = data.data.Part.item_id;
            } else if (data.data.Roof.id !== null) {
              this.setmode('roofphoto');
              this.roof_id = data.data.Roof.id;
              this.item_id = data.data.Roof.item_id;
              console.log(this.roof_id);
            }

            //console.log(this.data);
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  settype(type: string) {
    if (
      type !== '' &&
      type !== 'nameplate' &&
      type !== 'front' &&
      type !== 'measure'
    ) {
      alert('指定したtypeが不正です。' + type);
      return;
    }
    this.type = type;
  }

  setmode(mode: string) {
    if (
      mode !== '' &&
      mode !== 'itemphoto' &&
      mode !== 'roofphoto' &&
      mode != 'partphoto' &&
      mode != 'roofphotoselect'
    ) {
      alert('指定したmodeが不正です。' + mode);
      return;
    }
    this.mode = mode;
  }

  setworkmode(workmode: any) {
    //作業フローを特定するため
    if (workmode === null || workmode === false) {
      this.workmode = '';
      return;
    }
    if (
      workmode != 'partselect' //破損箇所マーク設置モード
    ) {
      alert('指定したsubmodeが不正です。' + workmode);
      return;
    }
    this.workmode = workmode;
  }

  work_is_partselect(): boolean {
    return this.workmode === 'partselect';
  }

  is_itemphoto(): boolean {
    return this.mode === 'itemphoto';
  }

  is_roofphoto(): boolean {
    return this.mode === 'roofphoto';
  }

  is_partphoto(): boolean {
    return this.mode === 'partphoto';
  }

  is_roofphotoselect(): boolean {
    return this.mode === 'roofphotoselect';
  }

  async savePhotoToServer() {
    await this.savepicture();
  }

  async savepicture() {
    //photo.idでpictureデータを保存する。
    //自分が撮影した画像かどうかはわからないが、
    //手元には画像データがある。
    //サーバのmd5と比較して、存在しなければ、送信する
    const result = await this.checkmd5(
      this.currentId,
      this.md5sum(this.currentImage.replace(/^.*,/, ''))
    );
    this.savePhotoToStorage(
      this.currentId,
      this.currentImage.replace(/^.*,/, '')
    );
    if (result == 'nofile') {
      var apiurl =
        environment.apiurl +
        'photos/api_savepicturefile/' +
        this.currentId +
        '.json';
      return this.Util.http_post(apiurl, {
        imageData: this.currentImage.replace(/^.*,/, ''),
      }).then((result: any) => {
        return true;
      });
    }
  }

  savePhotoToStorage(id: string, data: string) {
    this.storage
      .set('photo_' + id, {
        data: data,
        updated: new Date().getTime(),
      })
      .then((value) => {
        console.log(id + 'のPhotoデータをストレージに保存しました。');
      });
  }

  async getPhotoFromStorage(id: string) {
    const d = await this.storage.get('photo_' + id);
    console.log(id + 'のPhotoデータをストレージから読み込みました。');
    //console.log(d);
    if (d === null) {
      return null;
    }
    if (d.data !== null) {
      this.currentImage = 'data:image/jpeg;base64,' + d.data;
      return d.data;
    }
  }

  _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  async getPhotoFromServer(id: string) {
    if (true) {
      return new Promise((resolve, fail) => {
        var url =
          environment.apiurl + 'photos/api_getphotobinary/' + this.currentId;
        this.Util.http_get_binary(url).then((result: any) => {
          //console.log(result);
          var base64String;
          this.Util.readBase64(result).then((res) => {
            base64String = res;
            this.currentImage = 'data:image/jpeg;base64,' + base64String;
            this.savePhotoToStorage(id, base64String);
            //console.log(base64String);
            resolve(base64String);
          });
        });
      });
    } else {
    }
  }

  mergePhotoSrc(obj, data) {
    var x = obj;
    for (let i = 0; i < x.length; i++) {
      if (x[i]?.objectid == 'mainimage') {
        x[i].src = 'data:image/jpeg;base64,' + data;
      }
    }
    //console.log(x);
    return x;
  }

  checkmd5(id, md5) {
    //サーバからidのmd5を受け取り比較。
    if (
      typeof this.data !== 'undefined' &&
      typeof this.data.Photo !== 'undefined'
    ) {
      //md5はapi_viewで受信済み
      if (this.data.Photo.md5 === false) {
        return 'nofile';
      } else if (this.data.Photo.md5 !== md5) {
        return 'remote';
      } else if (this.data.Photo.md5 === md5) {
        return 'local';
      }
    } else {
      //新規の場合は、this.data.Photo.md5はないので、
      return 'nofile';
    }
  }

  async getPhotoData(id) {
    var photodata = await this.getPhotoFromStorage(id);
    if (photodata === null) {
      //サーバから取得して、保存してから返す
      return this.getPhotoFromServer(id);
    }

    const result = await this.checkmd5(this.currentId, this.md5sum(photodata));
    console.log(result);
    if (result === 'remote') {
      //サーバから取得して、保存してから返す
      return await this.getPhotoFromServer(id);
    } else if (result === 'local') {
      //localが返ってきたらそのまま返す
      return photodata;
    }
  }

  stripMainimageSrc(obj) {
    var tmp = JSON.parse(obj);
    var x = tmp.objects;
    for (let i = 0; i < x.length; i++) {
      if (x[i]?.objectid == 'mainimage') {
        x[i].src = '';
      }
    }
    tmp.objects = x;
    return JSON.stringify(tmp);
  }

  getmainimagedata(obj) {
    //抜き出しはこれでOK．
    //削除
    //内容の検証→md5
    for (let i = 0; i < obj.length; i++) {
      if (obj[i]?.objectid == 'mainimage') {
        return obj[i].src;
      }
    }
  }

  md5sum(str: string) {
    return Md5.hashStr(str).toString();
  }

  async save() {
    var api_url = '';
    if (this.mode == 'itemphoto') {
      api_url =
        environment.apiurl +
        'photos/api_saveitempicturefile/' +
        this.item_id +
        '.json';
    } else if (this.mode == 'roofphoto') {
      //屋根俯瞰図写真
      api_url =
        environment.apiurl +
        'photos/api_saveroofpicturefile/' +
        this.roof_id +
        '.json';
    } else {
      // partphoto
      api_url =
        environment.apiurl +
        'photos/api_savepartpicturefile/' +
        this.part_id +
        '.json';
    }
    api_url += '?' + new Date().getTime();
    return new Promise((resolve, fail) => {
      this.http
        .post(api_url, this.currentImage.replace(/^.*,/, ''), {
          headers: this.Util.httpheader,
        })
        .subscribe(
          (data: any) => {
            this.currentId = data.photo_id;
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  async unlink(id: string) {
    return new Promise((resolve, fail) => {
      this.http
        .post(
          environment.apiurl + 'photos/api_unlink/' + id + '.json',
          {},
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            //console.log(data);
            resolve(true);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  loadSaved(disptoast: boolean = true) {
    if (disptoast === true) {
      this.presentToast('photosを読込中');
    }
    this.storage.remove('photos'); // クリア;
    this.storage.remove('camerahistory'); // クリア;
    if (false) {
      this.storage.get('camerahistory').then((d) => {
        this.camerahistory = d || [];
        if (disptoast === true) {
          this.toast.dismiss();
        }
      });
    }
    //this.storage.remove('thumbs'); // クリア;
    this.storage.get('thumbs2').then((d) => {
      this.thumbs = d || {};
      //console.log(d);

      if (disptoast === true) {
        this.toast.dismiss();
      }
    });
  }

  /*
   メモリをそれなりに食うので、撮影後、ギャラリーに保存。
   管理はギャラリー側に任せられる。
  */
  saveToHistory(key: string, value: string) {
    if (this.is_android) {
      var albumname = '屋根破損レポートアプリ撮影履歴';
      this.photoLibrary
        .saveImage('data:image/jpeg;base64,' + value, albumname)
        .then((entry) => {
          //alert(albumname + 'に保存しました。');
        });
      return;
    }
    if (false) {
      var max = 30;
      this.camerahistory.unshift({
        data: value,
        name: key,
        created: new Date().getTime(),
      });
      this.camerahistory.splice(max);
      this.storage.set('camerahistory', this.camerahistory);
    }
  }

  saveThumb(key: string, value: string) {
    this.thumbs[key] = {
      data: value,
      updated: new Date().getTime(),
    };
    //メモリのため、件数制限
    this.thumbs = this.objectsplice(this.thumbs, 200);
    this.storage.set('thumbs2', this.thumbs);
  }

  objectsplice(obj, max = 3) {
    var i = 0;
    var nlists = {};
    //console.log(Object.keys(obj).length);
    Object.keys(obj).some(function (key) {
      if (i >= Object.keys(obj).length - max) {
        //console.log('残します', key);
        nlists[key] = this[key];
        //    return true;
      } else {
        //console.log('消します', key);
      }
      i++;
    }, obj);
    return nlists;
  }

  updateThumbTime(key, time) {
    this.thumbs[key].updated = time;
    this.storage.set('thumbs2', this.thumbs);
  }

  async presentToast(msg: string = '') {
    this.toast = await this.toastController.create({
      message: msg,
      duration: 20000,
      position: 'bottom',
    });
    this.toast.present();
  }

  getNewId() {
    return new Promise((resolve, fail) => {
      this.http
        .get(environment.apiurl + 'photos/api_getnewid.json', {
          headers: this.Util.httpheader,
        })
        .subscribe(
          (data: any) => {
            //console.log(data);
            this.currentId = data.photo_id;
            resolve(data.photo_id);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  saveCanvasToStorage(id: string, data, updated = null) {
    console.log('storageに保存します。');
    this.storage.set('canvas2_' + id, {
      data: data,
      updated: updated,
    });
  }

  updateCanvasTime(id: string, time) {}

  async getcanvastime(id: string) {
    return new Promise((resolve, fail) => {
      this.http
        .get(
          environment.apiurl +
            'photos/api_getcanvastime/' +
            id +
            '.json?' +
            new Date().getTime(),
          { headers: this.Util.httpheader }
        )
        .subscribe(
          (data: any) => {
            resolve(data.updated);
          },
          (error) => {
            fail(false);
          }
        );
    });
  }

  async readCanvasFromStorage(id: string) {
    const d = await this.storage.get('canvas2_' + id);
    if (d === null) {
      console.log('ローカルにデータはありません。');
      return null;
    }
    console.log('canvasをStorageから読み込みました。', d);

    var localtime = d.updated;
    //時間のチェックをする。
    var servertime = this.data.Photo.objectdataupdated;
    //var servertime = await this.getcanvastime(id);
    console.log(' ローカル: ', localtime, 'サーバ: ', servertime);
    if (localtime < servertime) {
      console.log('サーバの方が新しいようです。');
      return null;
    }
    console.log('ローカルの方が新しいようです。');
    return d.data;
  }

  async changerooftype(id: string, type = '') {
    await this.Util.http_post(
      environment.apiurl + 'photos/api_changerooftype/' + id + '.json',
      { type: type }
    ).then((result) => {
      this.get(id);
    });
  }
}

class Photo {
  data: any;
}
