import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { NavController, Platform } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  public is_debug: boolean = false;
  public toast: any;
  public httpheader;
  public usertoken: string = null;
  public progress_total: number;
  public progress_loaded: number;
  public progress_percent: string = null;
  public progress_val: number = null;
  public apptoken: string = 'skqihqnob06pznzzWtihesyqwPltpmjthbdl';
  public downloadprogress;
  public downloadtotal;
  public downloadprogress_percent;
  public downloadprogress_val;

  constructor(
    private router: Router,
    private nav: NavController,
    private storage: Storage,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public http: HttpClient,
    public platform: Platform
  ) {
    if (!environment.production) {
      this.is_debug = true;
    }
    if (this.usertoken === null) {
      if (this.platform.url().match(/\/admin\//)) {
        this.usertoken = localStorage.getItem('admin_usertoken');
      } else {
        this.usertoken = localStorage.getItem('usertoken');
      }
    }
    this.httpheader = new HttpHeaders({
      'X-Token': this.apptoken,
      'X-User-Token': this.usertoken,
    });
  }

  setusertoken(usertoken: string) {
    this.usertoken = usertoken;
    this.httpheader = new HttpHeaders({
      'X-Token': this.apptoken,
      'X-User-Token': this.usertoken,
    });
  }

  redirect(to: string, comment: string = '') {
    if (!environment.production) {
      console.log(to + 'にリダイレクトします。', comment);
      //alert(to + 'にリダイレクトします。');
    }

    this.router.navigateByUrl(to);
  }

  redirectBack(to: string, comment: string = '') {
    if (!environment.production) {
      console.log(to + 'にバックリダイレクトします。', comment);
      //alert(to + 'にバックリダイレクトします。');
    }
    this.nav.navigateBack(to);
  }

  redirecttoHome() {
    //this.nav.navigateBack('');
    this.router.navigate(['/'], { replaceUrl: true });
  }

  async presentToast(
    msg: string = '',
    duration: number = 5000,
    position: any = 'middle'
  ) {
    this.toast = await this.toastController.create({
      message: msg,
      duration: duration,
      position: position,
    });
    this.toast.present();
  }

  dissmissToast() {
    this.toast.dismiss();
  }

  public loading;
  async presentLoading(msg: string) {
    this.loading = await this.loadingController.create({
      message: msg,
    });
    await this.loading.present();
  }

  dissmissLoading() {
    this.loading.dismiss();
  }

  http_get(url: string) {
    const req = new HttpRequest('GET', url + '?' + new Date().getTime(), {
      headers: this.httpheader,
      reportProgress: true,
    });
    return this._http(req);
  }

  http_post(url: string, postdata: {}) {
    const req = new HttpRequest(
      'POST',
      url + '?' + new Date().getTime(),
      postdata,
      {
        headers: this.httpheader,
        reportProgress: true,
      }
    );
    return this._http(req);
  }

  http_get_binary(url: string) {
    const req = new HttpRequest('GET', url + '?' + new Date().getTime(), {
      headers: this.httpheader,
      reportProgress: true,
      //responseType: 'blob' as 'json',
      responseType: 'blob',
      observe: 'events',
    });
    return this._http(req);
  }

  public httprequest;
  _http(req: any) {
    return new Promise((resolve, fail) => {
      this.httprequest = this.http.request(req);
      this.httprequest.subscribe(
        (event: HttpEvent<any>) => {
          //console.log(event);
          switch (event.type) {
            case HttpEventType.Sent: //0
              console.log('送信開始します。', event);
              break;
            case HttpEventType.UploadProgress: //1
              this.progress_loaded = event.loaded;
              this.progress_total = event.total;
              //this.presentToast(event.loaded + ' / ' + event.total);
              var percent: string;
              this.progress_percent = percent =
                Math.round((100 * event.loaded) / event.total) + '%';
              console.log(
                (this.progress_val =
                  Math.round((event.loaded / event.total) * 100) / 100)
              );
              const percentDone = Math.round(
                (100 * event.loaded) / event.total
              );
              console.log(
                'アップロード中です',
                ` ${percentDone}% : ${event.loaded} / ${event.total}`,
                event
              );
              break;
            case HttpEventType.ResponseHeader: //2
              console.log('レスポンスを受信しました。body受信します。', event);
              //this.presentToast('データをロードしています。');
              break;
            case HttpEventType.DownloadProgress: //3
              //console.log('受信中です', event);
              if (event.total) {
                this.progress_loaded = event.loaded;
                this.progress_total = event.total;
                //this.presentToast(event.loaded + ' / ' + event.total);
                var percent: string;
                this.progress_percent = percent =
                  Math.round((100 * event.loaded) / event.total) + '%';
                this.progress_val =
                  Math.round((event.loaded / event.total) * 100) / 100;
                console.log(
                  'ダウンロード中です。',
                  `progress ${percent} : ${event.loaded} / ${event.total}`,
                  event
                );
              }
              break;
            case HttpEventType.Response: //4
              //console.log('受信完了です。');
              this.progress_total = null;
              this.progress_loaded = null;
              this.progress_percent = null;
              this.progress_val = null;
              //this.dissmissToast();
              if (this.platform.url().match(/\/admin\//)) {
                console.log(this.platform.url());
              }

              resolve(event.body);
          }
        },
        (err) => {
          this.progress_total = null;
          this.progress_loaded = null;
          this.progress_percent = null;
          this.progress_val = null;
          if (err.status == 401) {
            console.log('認証エラーです');
            if (this.platform.url().match(/\/admin\//)) {
              this.redirectBack('/adminlogout');
            } else {
              this.redirectBack('/logout');
            }
          }
          //console.log(err);
          fail(err);
        }
      );
    });
  }

  async readBase64(result) {
    return new Promise((resolve, fail) => {
      var reader = new FileReader();
      reader.readAsDataURL(result);
      var base64String;
      reader.onloadend = function () {
        base64String = reader.result;
        //console.log(base64String);
        resolve(base64String.replace(/^.*,/, ''));
      };
    });
  }
}
